<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="lg"
      :opacity=".7"
    >
      <ValidationObserver
        v-if="!loading"
        ref="form"
      >
        <Form
          :clinic-types-data.sync="formData"
          @formSubmit="doFormSave"
        />
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay } from 'bootstrap-vue'
import _ from 'lodash'
import axiosIns from '@/libs/axios'
import store from '@/store'
import { FormDataConverter } from '@/mixins/formData'
import Form from './Form.vue'

const emptyFormData = Object.freeze({
  id: null,
  slug: '',
  name: '',
})

export default {
  name: 'ClinicTypesUpdate',
  components: {
    BCard,
    BOverlay,
    Form,
  },
  metaInfo: {
    title: 'Редактирование типа клиники',
  },
  data() {
    return {
      formData: _.cloneDeep(emptyFormData),
      loading: true,
      serverErrors: {},
    }
  },
  mounted() {
    const promises = []

    promises.push(() => this.doLoadClinicType())

    this.loadingPromise(promises)
  },
  beforeDestroy() {
    store.commit('app/UPDATE_CURRENT_PAGE_TITLE', null)
  },
  methods: {
    async doFormSave() {
      this.loading = true

      const formData = new FormDataConverter(this.formData)

      try {
        const res = await axiosIns({
          method: 'POST',
          url: `/clinic_types/${this.$route.params.id}/update`,
          data: formData.getData(),
        })
        this.loading = false
        if (res.data.status === 'success') {
          await this.$router.push({ name: 'clinic-types' })
          this.sendToast({
            title: 'Успех!',
            icon: 'CheckCircleIcon',
            text: 'Тип клиники успешно обновлен!',
            variant: 'success',
          })
        }
      } catch (error) {
        this.loading = false
        if (error.response.status === 500) {
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Ошибка на сервере, попробуйте еще раз!',
            variant: 'danger',
          })
        }
        if (error.response.status === 422) {
          this.serverErrors = error.response.data.data
          this.$refs.form.setErrors(this.serverErrors)
          this.sendToast({
            title: 'Ошибка!',
            icon: 'AlertCircleIcon',
            text: 'Не все поля заполнены!',
            variant: 'danger',
          })
        }
      }
    },
    async doLoadClinicType() {
      const response = await axiosIns({
        method: 'GET',
        url: `/clinic_types/${this.$route.params.id}/show`,
      })

      this.formData = _.merge(this.formData, response.data.data)

      return response
    },
    loadingPromise(promises) {
      const onlyPromises = []
      this.loading = true

      // Если это функции на обновление фильтров и тп, то запускаем их
      promises.forEach(fn => {
        if (typeof fn === 'function') {
          onlyPromises.push(fn())
        } else {
          onlyPromises.push(fn)
        }
      })

      Promise.all(onlyPromises)
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>
